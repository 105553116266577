import { generateLink } from "./utils"

export function generateCodeSection(wrapper, config, currentConfig) {
  const groups = [{ elements: [] }];
  //groups code section;
  Array.from(wrapper.querySelectorAll("pre > code:first-child")).forEach(function (code, index) {
    const parentPre = code.parentNode;
    const group_index = groups.length - 1;
    const last_group = groups[group_index];
    last_group.elements.push(code);


    //generate new group

    if (!(parentPre.nextElementSibling && parentPre.nextElementSibling.nodeName === 'PRE')) {
      //generate container
      const codeContainer = document.createElement('codetab');
      codeContainer.setAttribute("data-index", group_index + "");
      parentPre.parentNode.insertBefore(codeContainer, parentPre);
      //
      groups[group_index] = processCodeSection(last_group, group_index, codeContainer, config, currentConfig);

      //
      // start a new group
      groups.push({ elements: [] })
    }

    parentPre.parentNode.removeChild(parentPre);

  });

  // remove last empty group
  groups.splice(groups.length - 1, 1);

  return groups

}


function processCodeSection(group, group_index, codeContainer, config, currentConfig) {

  // generate title
  const titleSection = group.elements[0];

  let title = null;
  let id = null;
  if (titleSection.getAttribute("class") && titleSection.getAttribute("class").trim() === "title") {
    title = titleSection.innerText.trim();
    id = generateLink(title + group_index);
    //remove title section
    group.elements.splice(0, 1)

  } else {
    title = "Code section - " + (group_index + 1);
    id = generateLink(title);
  }


  // generate description

  const descriptionSection = group.elements[0];

  let description = null;
  if (descriptionSection.getAttribute("class") && descriptionSection.getAttribute("class").trim() === "description") {
    description = descriptionSection.innerHTML;
    //remove description section
    group.elements.splice(0, 1);

  }

  const groups = _groupSubCodeSection(group, config, currentConfig);


  return {
    id,
    title,
    description,
    groups
  }


}


const _groupSubCodeSection = function (group, config, currentConfig) {
  // generate code section

  const codeGroups = [
    {
      title: "",
      content: ""
    }
  ];


  // loop all code section
  group.elements.reduce(function (codeGroups, el, index) {

    const cssClass = el.getAttribute("class");


    if (!cssClass) {

      codeGroups.push({
        title: index ? "Code Snippet - " + index : "Code Snippet",
        content: _processCodeContent(el, "text")
      })

    } else {
      //title


      let sectionTitle = null;

      // title like : ```[DME]json-Response
      const sections = cssClass.split("]");
      if (sections.length > 1) {
        sectionTitle = sections[0].substr(1); // remove first [
        // remove sectionTitle
        sections.splice(0, 1);
      }

      //```json-Response

      const _titles = sections[0].trim().split("-");

      const codeType = _titles[0];

      let codeTitle = _titles.length > 1 ? _titles.slice(1).join(" ") : _titles[0]; // ```json-API-Result
      if (config
        && config.codeSectionTitleMapping
        && config.codeSectionTitleMapping[codeTitle]) {
        codeTitle = config.codeSectionTitleMapping[codeTitle]
      }
      // sectionTitle = sectionTitle ? sectionTitle : codeTitle;


      const content = _processCodeContent(el, codeType);


      // if there is section, ```[section_name]
      if (sectionTitle) {

        // create a new group
        if (codeGroups[codeGroups.length - 1].title !== sectionTitle) {

          codeGroups.push({
            title: sectionTitle,
            content: document.createElement("div")
          });

        }

        const _lastGroup = codeGroups[codeGroups.length - 1].content;

        const title_el = document.createElement("h5");
        title_el.innerText = codeTitle;
        _lastGroup.appendChild(title_el);

        _lastGroup.appendChild(content);


      } else {


        codeGroups.push({
          title: codeTitle,
          content: content
        });


      }

      if (currentConfig && currentConfig.showLineNumbers) {
        el.classList.add('line-numbers')
      }

    }

    return codeGroups;
  }, codeGroups);

  return codeGroups.slice(1); // remove first empty object
};


const _processCodeContent = function (el, codeType) {

  if (codeType === "text") {
    codeType = "markup"
  }

  if (!codeType) {
    el.classList.add("language-none");
  } else {
    el.classList.add("language-" + codeType);
    el.classList.remove(codeType);
  }



  return el;
};
