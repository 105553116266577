/**
 * Created by aaikepae on 3/22/17.
 */
import initialState from '../store/initialState';

export default function reducer(state = initialState.states, action) {
  if (action.type == "UPDATE_STATE") {
    const new_state = {...state};
    const component = new_state[action.payload.component] = new_state[action.payload.component] || {};
    component[action.payload.key] = action.payload.value;
    return new_state
  } else if (action.type == "CLEAR") {
    return {...initialState.states}
  }

  return state
}
