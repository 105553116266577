import initialState from '../store/initialState';

export default function reducer(state = initialState.search, action) {
  if (action.type == "UPDATE_STATE") {
    return {...state, [action.payload.key]: action.payload.value}
  } else if (action.type == "FETCH_SEARCH_FULFILLED") {
    return {...state, result: action.payload.data, state: "fetched"}
  } else if (action.type == "FETCH_SEARCH_REJECTED") {
    return state
  } else if (action.type == "CLEAR") {
    return {...initialState.states}
  }else if (action.type == "CLEAR_SEARCH") {
    return {...initialState.states}
  }
  return state
}
