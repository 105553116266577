import React from "react";

import './index.scss';

function Icon({
  type,
  title = undefined,
  size = 16,
  color = undefined,
  className,
  onClick = undefined,
  disabled = false,
}) {
  return (
    <i
      role="button"
      tabIndex={onClick ? 0 : -1}
      onClick={onClick && (() => onClick())}
      onKeyDown={onClick && ((e) => e.key === 'Enter' && onClick())}
      style={{ color, height: size, width: size }}
      title={title}
      className={[`doc-icon doc-icon--${type}`, disabled && 'icon--disabled', className].filter((i) => i).join(' ')}
    >
      <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        {type === 'search' && <path d="M14 13.3723L10.6479 10.0202C11.4534 9.05322 11.8551 7.81289 11.7693 6.55728C11.6836 5.30166 11.1171 4.12742 10.1877 3.27884C9.25827 2.43026 8.03746 1.97267 6.77925 2.00126C5.52103 2.02986 4.32227 2.54243 3.43235 3.43235C2.54243 4.32227 2.02986 5.52103 2.00126 6.77925C1.97267 8.03746 2.43026 9.25827 3.27884 10.1877C4.12742 11.1171 5.30166 11.6836 6.55728 11.7693C7.81289 11.8551 9.05322 11.4534 10.0202 10.6479L13.3723 14L14 13.3723ZM2.90343 6.8982C2.90343 6.10811 3.13772 5.33576 3.57667 4.67883C4.01562 4.02189 4.63952 3.50987 5.36947 3.20752C6.09942 2.90516 6.90263 2.82605 7.67754 2.98019C8.45245 3.13433 9.16424 3.5148 9.72292 4.07347C10.2816 4.63215 10.6621 5.34395 10.8162 6.11886C10.9703 6.89377 10.8912 7.69698 10.5889 8.42693C10.2865 9.15688 9.77451 9.78077 9.11757 10.2197C8.46063 10.6587 7.68829 10.893 6.8982 10.893C5.83908 10.8918 4.82368 10.4705 4.07477 9.72163C3.32586 8.97272 2.90461 7.95731 2.90343 6.8982Z" fill="currentColor" />}
        {type === 'list' && (
          <>
            <path d="M14 3H5V4H14V3Z" fill="currentColor"/>
            <path d="M14 12H5V13H14V12Z" fill="currentColor"/>
            <path d="M14 7.5H5V8.5H14V7.5Z" fill="currentColor"/>
            <path d="M3 7.5H2V8.5H3V7.5Z" fill="currentColor"/>
            <path d="M3 3H2V4H3V3Z" fill="currentColor"/>
            <path d="M3 12H2V13H3V12Z" fill="currentColor"/>
          </>
        )}
        {type === 'close' && <path d="M8 1C4.1 1 1 4.1 1 8C1 11.9 4.1 15 8 15C11.9 15 15 11.9 15 8C15 4.1 11.9 1 8 1ZM10.7 11.5L8 8.8L5.3 11.5L4.5 10.7L7.2 8L4.5 5.3L5.3 4.5L8 7.2L10.7 4.5L11.5 5.3L8.8 8L11.5 10.7L10.7 11.5Z" fill="currentColor"/>}
        {type === 'arrow' && <path d="M9 3L8.285 3.6965L12.075 7.5H2V8.5H12.075L8.285 12.2865L9 13L14 8L9 3Z" fill="currentColor"/>}
        {type === 'arrow-up-right' && <path d="M5 3V4H11.295L3 12.295L3.705 13L12 4.705V11H13V3H5Z" fill="currentColor"/>}
        {type === 'chevron' && <path d="M11.0008 8L6.00078 13L5.30078 12.3L9.60078 8L5.30078 3.7L6.00078 3L11.0008 8Z" fill="currentColor"/>}
      </svg>
    </i>
  );
}

export default Icon;
