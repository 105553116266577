/**
 * Created by aaikepae on 11/4/16.
 */


export function updateLocation(value) {
  return {
    type: "UPDATE_CONFIG_LOCATION",
    payload: value
  }

}

export function updateConfig(value) {
  return {
    type: "UPDATE_CONFIG",
    payload: value
  }
}
