/**
 * Created by aaikepae on 11/3/16.
 */
import React from "react"
import {connect} from "react-redux"
import {bindActionCreators} from 'redux';

import {loadJSFile} from "../../../common/utility/utils";

import {loadContent} from "../../actions/content";

import SearchList from "./SearchList"
import Static from "./contentType/Static"
import HTML from "./contentType/HTML"
import { shouldUseWebComponent, WebComponent } from "./contentType/Webcomponent"

// import Footer from "./Foorter"
// import {Code} from "./Code";

export class Content extends React.Component {
    componentWillMount() {
        const {selected} = this.props;
        if (selected["item"] && !selected.data) {
            this.props.loadContent(selected.item);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { selected, config, instance } = nextProps;
        //when select item, trigger content generation
        if (selected["item"] && !selected.data && selected["item"] !== this.props.selected["item"]) {
            if (instance && instance.content && instance.content[selected.item.url]) {
                this.props.dispatch({
                    type: 'FETCH_STATIC_CONTENT_FULFILLED',
                    payload: { data: instance.content[selected.item.url] },
                })
            } else {
                this.props.loadContent(selected.item);
            }
        }
        if (config.useNPS && config.useNPS !== this.props.config.useNPS) {
            loadJSFile(CONFIG.NPS_SDK).then(() => {
                if (window.NpsSDK) {
                    window.NpsSDK.loadForm(config.useNPS);
                }
            })
        }
    }

    render() {

        const {selected, config, items, search, router} = this.props;

        if (!items || items.length == 0) {
            return <div className="overlay">
                <div className="loading-img"/>
            </div>;
        }


        if (search["keyword"]) {
            return <div className="pubhub-doc-container pubhub-search-container">
                <section>
                    <SearchList router={router} />
                </section>
            </div>
        }

        if (!selected["data"] || !selected.item) {
            return <div className="overlay">
                <div className="loading-img"/>
            </div>;
        }


        //bottom navigation
        let child, isIncludeWidget;
        const type = selected.item.type;



        if (type == "file") {
            window.open(config.location + selected.item.content, "_blank");
            child =
                <p>Please click <a href={config.location + selected.item.content} target="_blank">here</a> if this file
                    does not
                    open automatically!</p>;

        } else if (['html', 'swagger', 'swagger3', 'asyncapi', 'raml'].indexOf(type) !== -1) {
            child = <HTML selected={selected} data={selected["data"]} config={config} {...this.props} ref="doc"/>;

        } else if (shouldUseWebComponent(type)) {
            child = <WebComponent selected={selected} data={selected["data"]} config={config} {...this.props}
                                  ref="doc"></WebComponent>
        } else {
            child = <Static key={selected.orphenLinkUrl || 'normal-static'} selected={selected} data={selected["data"]} config={config} {...this.props} ref="doc"
              onStartLoadOrphenLink={this.props.onStartLoadOrphenLink}
              onOrphenLinkGoback={this.props.onOrphenLinkGoback}/>;

            isIncludeWidget = true
        }
        // const footer = <Footer config={this.props.config} selected={this.props.selected}/>;
        const widgetID = isIncludeWidget ? "pubhub-widgets" : "";

        let codeLayoutClass = config.codeLayout;
        if ((codeLayoutClass == "two-columns" && config["_platform"] !== "lg") || (selected.codeSections && selected.codeSections.length == 0)) {
            codeLayoutClass = "one-column"
        }
        const widgetClass = ["doc-content", type, codeLayoutClass].join(" ");


        return (
            <div className={"pubhub-doc-container " + config.layout} ref="root">
                <section className={widgetClass} id={widgetID}>
                    {child}
                </section>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadContent: bindActionCreators(loadContent, dispatch)
    };
}

export default connect(null, mapDispatchToProps, null, {withRef: true})(Content);
