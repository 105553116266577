import React from "react";
import { escapeRegex } from '../../../../common/utility/regex';

export default class  extends React.Component {
  render() {
    const {keyword, list, meta, router, listIndex} = this.props;

    if (!keyword) {
      return null;
    }

    const reg = new RegExp(escapeRegex(keyword), 'gi');

    const lis = list.map((item, index) => {
      const title = item.title.replace(reg, (x) => `<b>${x}</b>`);
      return (
        <li className={index === listIndex ? 'pubhub-nav-search-list-item-active' : undefined} key={item.sequence}>
          <a
            href={item.url}
            dangerouslySetInnerHTML={{ __html: title }}
            onClick={(event) => {
              if (router.mode === 'history') {
                event.preventDefault();
                router.queryString = item.url.replace(router.baseURL, '');
              }
            }}
          />
        </li>
      );
    });

    return (
      <div className="pubhub-nav-search-list">
        <ul>{lis}</ul>
        {meta.indexed && (
          <a
            className="searchContent"
            href={`#search/${encodeURIComponent(keyword)}`}
            onClick={(event) => {
              if (router.mode === 'history') {
                event.preventDefault();
                router.queryString = `search/?q=${encodeURIComponent(keyword)}`;
              }
            }}
          >
            See all results for "{keyword}"
          </a>
        )}
      </div>
    )
  }
}
