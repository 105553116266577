import React from "react";

export default class Setting extends React.Component {
    state = {
        html: ''
    }

    componentDidUpdate(prevProps) {
        if (this.props.selected.data && this.props.selected.data && prevProps.selected.data !== this.props.selected.data) {
            if (this.props.config["include_header"] && !this.state.html) {
                const header_html_fragment_url = this.props.config.location + "header.html";
                fetch(header_html_fragment_url)
                    .then((res) => res.text())
                    .then((text) => {
                        const node = document.getElementById('devnet-doc-header');
                        if (node) {
                            node.innerHTML = text;
                        } else {
                            this.setState({ html: text });
                        }
                    }).catch((e) => {
                        console.error(e)
                    })
            }
        }
    }

    render() {
        const {items, meta} = this.props;
        const { html } = this.state;

        if (!items || !meta) {
            return null
        }

        return <div className="doc-config-header" dangerouslySetInnerHTML={{ __html: html }} />

    }
}
