import React from "react";
import DocLabel from './docLabel';

import "./index.scss";

export default class extends React.Component {
    render() {
        // will switch to metadata tool later
        const { meta, config } = this.props;

        const arrow = ">";
        let title = meta ? meta.name : "";
        let breadcrumb = null;
        if (config && config.breadcrumb) {
            breadcrumb = config.breadcrumb;
        }

        let product = '';
        if (meta && meta.products) {
            product = meta.products.split(',')[0];
        }
        
        return (
            <section className="doc-breadcrumb">
                <nav className="doc-breadcrumb__container">
                    <ol className="doc-breadcrumb__links">
                        {breadcrumb ?
                            breadcrumb.map((nav, i) => (
                                <li>
                                    {i > 0 && <i className="doc-breadcrumb__arrow" />}
                                    {nav.url ? (
                                        <a className="doc-breadcrumb__link" href={nav.url}>{nav.title}</a>
                                    ) : nav.title}
                                </li>
                            )
                        ) : (
                            <>
                                <li>
                                    <a className="doc-breadcrumb__link" href="/docs/">Documentation</a>
                                    <i className="doc-breadcrumb__arrow" />
                                </li>
                                <li>
                                    <a className="doc-breadcrumb__link" href="/docs/search/">All</a>
                                    <i className="doc-breadcrumb__arrow" />
                                </li>
                                {product && (
                                    <li>
                                        <a className="doc-breadcrumb__link" href={`/docs/search/?products=${encodeURIComponent(product)}`}>{product}</a>
                                        <i className="doc-breadcrumb__arrow" />
                                    </li>
                                )}
                                <li>{title}</li>
                            </>
                        )}
                    </ol>
                    <DocLabel config={config} />
                </nav>
            </section>
        )
    }
}
