/**
 * Created by aaikepae on 11/3/16.
 */
import initialState from '../store/initialState';

export default function reducer(state = initialState.items, action) {
  if (action.type == "FETCH_ITEMS") {
    return action.payload.slice()
  } else if (action.type == "SELECT_ITEM") {
    //make a copy
    var _items = [...state];

    //reset previous active state
    (function reset(items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        item.active = false;
        if (item["items"]) {
          reset(item["items"])
        }
      }
    })(_items);

    //select new item
    var sequenceAry = action.payload.sequence.split(".");
    var item = {"items": _items};
    for (let i = 0; i < sequenceAry.length; i++) {
      item = item["items"][sequenceAry[i]] = {...item["items"][sequenceAry[i]], active: true};
    }
    // todo check
    return _items
  } else if (action.type == "CLEAR") {
    return []
  }
  return state
}


