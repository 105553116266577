import React from "react"
import {connect} from "react-redux"
import List from "./List"


export class Search extends React.Component {

    state = {
        "items": [],
        "keyword": null,
        "listIndex": -1,
    };

    constructor() {
        super();

        this._handleSearching = this._handleSearching.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.searchItems = this.searchItems.bind(this);
    }


    convertItemsToFlat(items, acc = []) {
        items.forEach((item) => {
            if (item.url) {
                acc.push({
                    sequence: item.sequence,
                    url: this.props.router.generateLink(item.alternative || item.url),
                    title: item.title
                })
            }

            if (item.items) {
                this.convertItemsToFlat(item.items, acc)
            }
        });

        return acc;
    }

    _handleSearching(event) {
        const {listIndex, items, keyword} = this.state;
        if (event.key === "ArrowUp") {
            this.changeListIndex(-1)
        } else if (event.key === "ArrowDown") {
            this.changeListIndex(1);
        } else if (event.key === "Enter") {
            if (keyword) {
                try {
                    if (items.length && listIndex !== -1) {
                        document.querySelector(`.pubhub-nav-search-list li.pubhub-nav-search-list-item-active a`).click();
                    } else {
                        document.querySelector(".pubhub-nav-search-list a.searchContent").click();
                    }
                } catch (e) {
                    console.error(e)
                }
            }


        } else {
            this.searchItems(event.target.value);
        }

    }

    changeListIndex(step) {
        const {listIndex, items} = this.state;
        let _index = listIndex + step;

        if (_index < 0) {
            _index = items.length - 1;
        }

        if (_index >= items.length) {
            _index = 0;
        }

        this.setState({
            listIndex: _index
        })


    }

    searchItems(keyword) {
        if (this._searchTimer) {
            clearTimeout(this._searchTimer);
        }

        this._searchTimer = setTimeout(() => {
            const {items} = this.props;
            const flatItems = this.convertItemsToFlat(items);
            const _items = flatItems.filter((item) => item.title.toLowerCase().indexOf(keyword.toLowerCase()) !== -1);
            // find ths first 10
            this.setState({
                "items": _items.slice(0, 10),
                "keyword": keyword
            });

        }, 100);
    }


    closePopup() {
        this.setState({
            "items": [],
            "keyword": null,
            "listIndex": -1
        });

        this.refs["input"].value = "";
    }

    render() {

        const searchingClass = this.state.keyword ? " pubhub-nav-searching" : "";

        return <div className={"pubhub-nav-search" + searchingClass}>
            <div className="pubhub-nav-search-box">
                <input type="text" onKeyUp={this._handleSearching} ref="input"/>
                <span onClick={this.closePopup}/>
            </div>
            <div onClick={this.closePopup}>
                <List
                    list={this.state.items}
                    keyword={this.state.keyword}
                    meta={this.props.meta}
                    listIndex={this.state.listIndex}
                    router={this.props.router}
                />
            </div>
        </div>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        itemsObj: state.itemsObj,
        items: state.items,
        meta: state.meta,
        selected: state.selected,
        config: state.config
    };
}

export default connect(mapStateToProps)(Search);
