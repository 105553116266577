/**
 * Created by aaikepae on 11/3/16.
 */

import React from "react"
import {connect} from "react-redux"

import NavItem from "./NavItem"

export default class NavList extends React.Component {
  render() {
    const {items} = this.props;
    if (items) {
      const children = items.map(function (item,index) {
        return <NavItem key={index} selected={this.props.selected} config={this.props.config} data={item} router={this.props.router} toggle={this.props.toggle}></NavItem>
      }.bind(this));

      return <ul className="pubhub-nav-items">{children}</ul>
    } else {
      return <span>loading</span>
    }

  }
}
