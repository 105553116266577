import {combineReducers} from "redux"

import items from "./items"
import meta from "./meta"
import config from "./config"
import itemsObj from "./itemsObj"
import selected from "./selected"
import preference from "./preference"
import states from "./states"
import search from "./search"

export default combineReducers({
  itemsObj,
  items,
  meta,
  config,
  selected,
  preference,
  states,
  search
})
