import React, { lazy, Suspense, useEffect, useState } from "react";

// import './_index.scss';
import './index.scss';

const Select = lazy(() => import(/* webpackChunkName: "react-select" */ 'react-select'));

export default function VersionPicker({ meta, router }) {
    const { baseURL } = router;
    const { version_group, state } = meta;
    const [options, setOptions] = useState([]);

    const status = state || (CONFIG.ENV === 'production' ? 'live' : 'pending');

    useEffect(() => {
        if (version_group) {
            fetch(`${CONFIG.API_HOST}/v1/sitedata/projects/pubhub/version?version_group=${version_group}&limit=100&project_status=${status}`, {
                credentials: 'include',
            })
                .then((res) => res.json())
                .then((res) => {
                    if (!res) return;
                    res.sort((a, b) => {
                        const labelA = a.version_name.replace(/( |^)\D+( |$)/g, '');
                        const labelB = b.version_name.replace(/( |^)\D+( |$)/g, '');
                        return labelB.localeCompare(labelA, undefined, { numeric: true })
                    });
                    const latestOption = res.find((option) => option.is_latest_version && option.canonical_url);
                    setOptions([
                        ...(latestOption ? [{ value: latestOption.canonical_url.replace(/\/?(\?|#|$)/, '/$1'), label: 'Latest' }] : []),
                        ...res.map((option) => ({ value: option.url, label: option.version_name }))
                    ]);
                });
        }
    }, [version_group]);

    return options.length > 0 && (
        <div className="pubhub-version">
            <Suspense fallback={<div>Loading...</div>}>
                <Select
                    classNamePrefix="pubhub-select"
                    value={options.find((option) => baseURL === option.value)}
                    options={options}
                    onChange={(option) => window.location.href = option.value}
                />
            </Suspense>
        </div>
    );
}

// export default class extends React.Component {
//     handleChange = (selectedOption) => {
//         if (selectedOption.url) {
//             window.location.href = selectedOption.url
//             return;
//         }

//         const id = selectedOption.value;
//         const route = Object.values(PUBHUB_PATH_LIST).find(i => i.id == id)
//         if (route) {
//             window.location.href = route.url
//         }

//     };
//     render() {

//         if (!window["PUBHUB_VERSION_LIST"]) {
//             return null
//         }

//         const PUBHUB_VERSION_LIST = window["PUBHUB_VERSION_LIST"];

//         const { meta, router } = this.props;

//         const metaId = getId(meta);
        
//         const group = Object.values(PUBHUB_VERSION_LIST).find((group) => {
//             return Object.values(group).find((subgroup) => {
//                 return subgroup.id == metaId
//             })
//         })

//         if (!group) {
//             return null
//         }

//         const groupList = Object.values(group);
//         const options = groupList.map((item) => {
//             return {
//                 value: item.id,
//                 label: item.label
//             }
//         });

//         options.sort((a, b) => b.label.localeCompare(a.label, undefined, { numeric: true }));

//         const latestOption = this.getLatestOption(groupList);
//         if (latestOption) {
//             options.splice(0, 0, latestOption);
//         }

//         const selectedOption = this.getSelectedOption(options, meta, latestOption, router);

//         return (
//             <div className={"pubhub-nav-vp-container"} id={"pubhub-nav-vp"}>
//                 <React.Suspense fallback={<div>Loading...</div>}>
//                     <Select
//                         styles={{ valueContainer: (style) => ({ ...style, padding: '0 14px' })}}
//                         value={selectedOption}
//                         options={options}
//                         classNamePrefix={"pubhub-nav-vp"}
//                         onChange={this.handleChange}
//                     />
//                 </React.Suspense>
//             </div>

//         )
//     }

//     getSelectedOption(options, meta, latestOption, router) {
//         const pathname = router.mode === 'history' ? router.baseURL : window.location.pathname;
//         if (latestOption && pathname == latestOption.url) {
//             return latestOption;
//         }

//         const metaId = getId(meta);
//         return options.find(item => item.value == metaId);
//     }

//     getLatestOption(groupList) {
//         const latestVersion = groupList.find(item => {
//             return item.is_latest_version && item.canonical;
//         });

//         if (!latestVersion) return null;

//         const url = this.getCanonicalUrl(latestVersion);

//         // latest version
//         return {
//             value: "Latest",
//             label: "Latest",
//             url: url
//         };
//     }

//     getCanonicalUrl(item) {
//         return (item.canonical && !item.canonical.endsWith("/")) ? (item.canonical + "/") : item.canonical;
//     }
// }

// function getId(meta) {
//     return meta.id || meta.project_id;
// }
