/**
 * Created by aaikepae on 11/3/16.
 */

import React, { useEffect, useMemo, useRef, useState } from "react";

import Item from './components/Item';
import Icon from './components/Icon';

import './index.scss';

function getNav(sequence, items) {
  let cItems = items;
  const navs = [];
  let indexes = sequence.split('.').filter((i) => (i >= '0'));

  while (indexes.length > 1 && cItems) {
    const item = cItems[indexes.shift()];
    cItems = item.items;
    navs.push(item);
  }

  return navs;
}

function getFlatList(items) {
  let list = [];
  list = list.concat(items.filter((item) => item.type && !item.alternative));
  items.forEach((item) => {
    if (item.items) {
      list = list.concat(getFlatList(item.items));
    }
  });

  return list;
}


function MobileNav(props) {
  const [keyword, setKeyword] = useState('');
  const [sequence, setSequence] = useState('');
  const [open, setOpen] = useState('');
  const [height, setHeight] = useState(0);
  const [versionList, setVersionList] = useState([]);
  const [showVersionList, toggleVersionList] = useState(false);
  const wrapper = useRef(null);

  const { items, selected, meta, docLabel, navTitleText, clickNavIconHandler, router } = props;

  useEffect(() => {
    if (selected.sequence && sequence === '') {
      setSequence(selected.sequence);
    }
  }, [selected]);
  
  useEffect(() => {
    const { version_group } = meta;
    if (version_group) {
      fetch(`${CONFIG.API_HOST}/v1/sitemeta/pubhub/projects/version?version_group=${version_group}&limit=10&project_status=${CONFIG.ENV === 'production' ? 'live' : 'pending'}`, {
        credentials: 'include',
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res) return;
          res.sort((a, b) => b.version_name > a.version_name ? 1 : -1);
          const latestOption = res.find((option) => option.is_latest_version && option.canonical_url);
          setVersionList([
            ...(latestOption ? [{ value: latestOption.canonical_url.replace(/\/?(\?|#|$)/, '/$1'), label: 'Latest' }] : []),
            ...res.map((option) => ({ value: option.url, label: option.version_name }))
          ]);
        });
    }
  }, [meta]);

  const navs = getNav(sequence, items);
  const listItem = navs.slice(-1)[0];

  const versionListHeight = versionList.length * 42 + versionList.length + 1;

  const flatList = useMemo(() => getFlatList(items), [items]);

  useEffect(() => {
    if (open) {
      document.documentElement.classList.add("restrict_body");
      document.body.classList.add("restrict_body");
      if (!height && wrapper.current) {
        const rect = wrapper.current.getBoundingClientRect();
        setHeight(rect.top + rect.height);
      }
    } else {
      document.documentElement.classList.remove("restrict_body");
      document.body.classList.remove("restrict_body");
    }
  }, [open, height, wrapper])

  useEffect(() => {
    const clickOutside = (e) => {
      if (showVersionList) {
        toggleVersionList(false);
      }
    }

    document.addEventListener('click', clickOutside);

    return () => document.removeEventListener('click', clickOutside);
  }, [showVersionList])

  if (!items || !meta) {
    return null
  }

  return (
      <div ref={wrapper} className="doc-header" onClick={clickNavIconHandler}>
        <div className="doc-header__top">
          <div className="doc-header__title">
            {docLabel}
            <span onClick={() => toggleVersionList(!showVersionList)}>{navTitleText}</span>
            {versionList.length > 0 && (
              <Icon onClick={() => toggleVersionList(!showVersionList)} type="expand" size={14} />
            )}
          </div>
          <ul style={{ height: showVersionList ? versionListHeight : 0, borderWidth: showVersionList ? 1 : 0 }} className="doc-header__version-list">
            {versionList.map((item) => (
              <li key={item.value} className="doc-header__version-item">
                <a className="doc-header__version-link" href={item.value}>{item.label}</a>
              </li>
            ))}
          </ul>
          <label htmlFor="search-input"><Icon onClick={() => setOpen('search')} className="doc-header__icon" type="search" size={28} /></label>
          <Icon onClick={() => setOpen(open === 'list' ? '' : 'list')} className={`doc-header__icon ${open === 'list' ? ' doc-header__icon--active' : ''}`} type="list" size={28} />
          <div style={{ left: open === 'search' ? undefined : '100%' }} className="doc-header__search">
            <div className="doc-header__search-wrapper">
              <Icon className="doc-header__icon doc-header__icon--search" type="search" size={20} />
              <input id="search-input" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="doc-header__search-input" placeholder="Find anything" />
              {keyword && (
                <Icon onClick={() => setKeyword('')} className="doc-header__icon doc-header__icon--close" type="close" />
              )}
            </div>
            <span onClick={() => setOpen('')} className="doc-header__search-cancel">Cancel</span>
          </div>
        </div>
        <div style={{ height: open ? `calc(100vh - ${height}px)` : undefined }} className="doc-header__list">
          {open === 'list' && (
            <ul className="doc-header__navs">
              {navs.length > 0 && <li className="doc-header__nav" onClick={() => setSequence('')}>Top</li>}
              {navs.length > 3 && (
                <li onClick={() => setSequence(`${navs.slice(-4)[0].sequence}.0`)} className="doc-header__nav">
                  <i className="doc-header__nav-divide" />
                  ...
                </li>
              )}
              {navs.slice(-3).map((nav) => (
                <li key={nav.sequence} className="doc-header__nav" onClick={() => setSequence(`${nav.sequence}.0`)}>
                  <i className="doc-header__nav-divide" />
                  {nav.title}
                </li>
              ))}
            </ul>
          )}
          <ul className="doc-header__items">
            {open === 'list' && (listItem ? listItem.items : items).map((item) => (
              <li key={item.url}>
                <Item item={item} setSequence={setSequence} selected={selected} router={router} onClick={() => setOpen('')} />
              </li>
            ))}
            {open === 'search' && <li className="doc-header__item doc-header__item--result">Results</li>}
            {open === 'search' && flatList.filter((item) => item.title.toLowerCase().indexOf(keyword.toLowerCase()) !== -1).slice(0, 8).map((item) => (
              <li>
                <Item item={item} setSequence={setSequence} selected={selected} router={router} keyword={keyword} onClick={() => setOpen('')} />
              </li>
            ))}
            {open === 'search' && !meta["indexed"] && keyword && (
              <li>
                <a className="doc-header__item" href={`#search/${encodeURIComponent(keyword)}`}>
                  <span>See all results for "{keyword}"</span>
                  <Icon className="doc-header__item-arrow" type="arrow" />
                </a>
              </li>
            )}
          </ul>
        </div>
    </div>
  );
}

export default MobileNav;
