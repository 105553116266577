/**
 * Created by aaikepae on 1/23/17.
 */


import React from "react"
import {loadJSFiles, executeHTMLScript, updateRelatedLink, removeVideoAutoPlay} from "../../../../common/utility/utils";
import {smoothScroll} from "../../../../common/utility/dom";

import Footer from "../Foorter";

export default class extends React.Component {


  // get absolute URI path of current content
  get contentURLPath() {
    const {config, selected} = this.props;
    if (config && config.location && selected && selected.item) {
      const contentPath = selected.item.content;
      return config.location + contentPath.substr(0, contentPath.lastIndexOf("/") + 1);
    }
  }

  // find pubhub instance's container

  get container() {
    if (this.refs) {
      const docEL = this.refs["doc"];
      return Array.from(document.querySelectorAll(".pubhub-container")).find(function (el) {
        return el.contains(docEL)
      });
    }
  }

  constructor(props) {
    super(props);
  }


  componentWillMount() {
    const self = this;
    const wrapper = document.createElement('div');
    wrapper.innerHTML = this.props.data;


    //
    updateRelatedLink(wrapper, this.contentURLPath, this.props.config.location);
    removeVideoAutoPlay(wrapper);

    loadJSFiles(wrapper, this.contentURLPath, function () {
      self.html = wrapper.innerHTML;
      self.forceUpdate();
      setTimeout(executeHTMLScript(self.refs["doc"]), 0)
    })
  }

  componentDidMount() {
    this.scrollToTop();
  }

  scrollToTop() {
    const _scroll_offset_top = document.querySelector(".pubhub-doc-container").offsetTop;
    const scrollingContainer = document.scrollingElement || document.documentElement;
    smoothScroll(scrollingContainer, _scroll_offset_top - 10, 400)
  }

  render() {
    const {config, selected} = this.props;

    if (this.props.data && this.html) {

      const contentClass = ["column-doc", selected.item.type, config.layout].join(" ");

      return (
        <div className={contentClass} ref="doc">
          <div className="pb-doc-content" dangerouslySetInnerHTML={{__html: this.html}}/>
          <Footer router={this.props.router} config={this.props.config} selected={this.props.selected}/>
        </div>
      )
    } else {
      return <div/>
    }
  }


}

