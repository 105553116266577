import React from "react"
import { connect } from "react-redux"
import List from "./List"
import Pagination from "./Pagination"
import SearchItem from "../../../util/keyword"

export class SearchList extends React.Component {
  state = {
    "items": [],
    "keyword": null
  };

  constructor() {
    super();

    this._handleSearching = this._handleSearching.bind(this);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
    const { search } = this.props;
    if (search.state === 'fetched') {
      let _container = window.PubHubDocInstance.container;
      const _event = new CustomEvent("search", {
        detail: {
          data: {
            q: search.keyword,
            page: search.page,
            result: {
              ...search.result,
              items: [],
            }
          },
        },
        bubbles: true,
        cancelable: true
      });
      _container.dispatchEvent(_event);
    }
  }

  _handleSearching(event) {
    this.search(event.target.value.toLowerCase());
  }

  _renderContent(search, meta, router) {
    if (search.state == "searching") {
      return (
        <span className="state-searching">Searching...</span>
      );
    }

    if (search.state == "fetched") {
      return (
        <div>
          <h4>Found {search.result.total} item(s) matching the search query.</h4>
          <List search={search} meta={meta} router={router} />
          <Pagination search={search} />
        </div>
      );
    }

    return null;
  }

  render() {
    const { search, meta, router } = this.props;
    const content = this._renderContent(search, meta, router);
    const searchKey = decodeURIComponent(search.keyword || '');
    const searchItem = SearchItem.parse(searchKey);
    
    return (
      <div className="pubhub-search">
        <h2>Search results for: <b>{searchItem.keyword}</b></h2>
        {content}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    search: state.search,
    meta: state.meta,
    config: state.config
  };
}

export default connect(mapStateToProps)(SearchList);
