import React from "react"
import {connect} from "react-redux"

export default class  extends React.Component {
  render() {
    const {result, page} = this.props.search;
    const keyword = encodeURIComponent(this.props.search.keyword);
    if (result.total === 0 || result.total <= 10) {
      return null;
    }

    const items = [];
    const totalPages = Math.ceil(result.total / 10);
    const section = Math.floor(page / 10.00001); //1-10 > 0; 11-20 >  2
    const currentPages = Math.min(totalPages - section * 10, 10) + section * 10; // 11 - 20 or 11 - 17
    let index = section * 10 + 1; // 1 - 10, 11- 20

    const router = PubHubDocInstance ? PubHubDocInstance.router : {};

    const isHistory = router.mode === 'history';
    const basePath = isHistory ? router.baseURL : '#';
    //range
    for (; index <= currentPages; index++) {
      const i = index;
      const activeClass = index == page ? "pubhub-search-pagination-item-active" : null;
      items.push(<div className={"pubhub-search-pagination-item " + activeClass} key={index}>
        <a
          href={`${basePath}search/${isHistory ? `?q=${keyword}&page=${i}` : `${keyword}/${i}`}`}
          onClick={(event) => {
            if (isHistory) {
              event.preventDefault();
              router.queryString = `search/?q=${keyword}&page=${i}`;
            }
          }}
        >
          {index}
        </a>
      </div>)
    }

    //arrow icons
    const left_arrow = page == 1 ? <i className="fal fa-angle-left disabled"/> :
      <a href={`${basePath}search/${isHistory ? `?q=${keyword}&page=` : `${keyword}/`}${parseInt(page) - 1}`}
      onClick={(event) => {
        if (router.mode === 'history') {
          event.preventDefault();
          router.queryString = `search/?q=${keyword}&page=${parseInt(page) - 1}`;
        }
      }}><i className="fal fa-angle-left"/></a>;
    const right_arrow = page == totalPages ? <i className="fal fa-angle-right disabled"/> :
      <a href={`${basePath}search/${isHistory ? `?q=${keyword}&page=` : `${keyword}/`}${parseInt(page) + 1}`}
      onClick={(event) => {
        if (router.mode === 'history') {
          event.preventDefault();
          router.queryString = `search/?q=${keyword}&page=${parseInt(page) + 1}`;
        }
      }}><i className="fal fa-angle-right"/></a>;

    return <div className="pubhub-search-pagination">
      {left_arrow}
      {items}
      {right_arrow}
    </div>
  }
}

