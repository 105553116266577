/**
 * Created by aaikepae on 11/3/16.
 */
import React from "react"
import {connect} from "react-redux"
import {bindActionCreators} from 'redux';
import {updateConfig} from '../../actions/config'
import {includeCodeSection} from "../../../common/utility/utils";


export default class Setting extends React.Component {

  constructor(props) {
    super(props);
    this.checked = true;
    this.change = this.change.bind(this);
  }

  change(event) {
    this.checked = !this.checked;
    this.props.dispatch({
      type: "UPDATE_CONFIG",
      payload: {
        key: "codeLayout",
        value: this.checked ? "two-columns" : "one-column"
      }
    });
  }


  render() {
    const {selected, config} = this.props;


    if (!config.showCodeSwitch) {
      return <span/>
    }

    if (!selected.data) {
      return <span/>
    }


    if (selected.item["config"] && selected.item["config"]["showCodeSwitch"] == false) {
      return <span/>
    }

    this.checked = config.codeLayout == "two-columns";

    return <div className="column-switch-container">
      <label className="switch">
        <input type="checkbox" className="switch-input" value="off" onChange={this.change}
               checked={this.checked}/>
        <span className="switch-label" data-on="On" data-off="Off"/>
        <span className="switch-handle"/>
      </label>
    </div>
  }
}


