export default {
    parse: function(keyword) {
        const key = (keyword || '').trim();
    
        return _splitKeywordByType(key, 'doc')
            || _splitKeywordByType(key, 'api')
            || {
                type: '',
                keyword: key
            }
    }
}

function _splitKeywordByType(keyword, type) {
    if (!keyword || !type) return null

    const lowerCaseType = type.toLowerCase();
    const lowerCaseKeyword = keyword.toLowerCase();
    const typeWithColon = lowerCaseType + ':';
    if (!lowerCaseKeyword.startsWith(typeWithColon)) return null;

    return {
        type: lowerCaseType,
        keyword: keyword.substr(typeWithColon.length).trim()
    }
}