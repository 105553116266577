/**
 * Created by aaikepae on 11/3/16.
 */
import initialState from '../store/initialState';

export default function reducer(state = initialState.itemsObj, action) {
    if (action.type == "FETCH_ITEMS") {
        //init
        const context = {
            obj: {},
            sequencelist: []
        };
        //generate context
        action.payload.forEach((item, index) => {
            processor(item, null, false, context, 0, index, '');
        });

        enhanceData(context);

        return context.obj
    } else if (action.type == "CLEAR") {
        return {}
    }
    return state
}


/**
 * generate sequence, and enhance model
 * data model
 * {
    "url": "static-markdown-file",
    "uuid": "ff94b259922fbe74ba9af4e5c9dfe5ed0f9124bd",
    "title": "Static markdown file",
    "content": "markdown/staticcontent.tpl",
    "type": "markdown",
    "parent":url,
    "read":false,
    "depth":0,
    "order":0,
    "active":true/false,
    "expand":true/false
    children:["url","url"....],
    items:[...items]
  }
 */


let expandSubDepth = 1;
let expandSubSequence = null;

function processor(data, parent, hierarchy, context, depth, order, parentSequence) {
    data["read"] = false;
    data["depth"] = depth;
    data["order"] = order;
    data["active"] = false;
    //generate sequence
    data["sequence"] = parentSequence + order;

    // defined at the root
    if (data["config"] && data["config"]["expand"] !== undefined) {
        expandSubDepth = data["config"]["expand"];
        expandSubSequence = data["sequence"];
    }

    // reset, if there is expandSubSequence, and the current item is not under this tree
    if (expandSubSequence && !data["sequence"].startsWith(expandSubSequence)) {
        expandSubSequence = null;
        expandSubDepth = 1;
    }


    data["expand"] = depth < expandSubDepth;


    /**
     * important!!!
     * determine if item is clickable
     *
     */
    // var sequence = null;
    // if (data["type"] && data["content"] && data["type"] !== "url") {
    //   sequence = data["sequence"] = "$" + _sequence;
    // } else {
    //   sequence = data["sequence"] = "." + _sequence;
    // }

    data["routable"] = !!(data["type"] && data["content"] && data["type"] !== "url");

    if (parent) {
        data.parent = parent;
    }

    // if data has url
    if (data["url"]) {
        if (hierarchy) {
            data["url"] = parent + '/' + data.url;
        }
        // build a url: sequence map
        context.obj[data["url"]] = data["sequence"];
        // store the flatten item list
        context.sequencelist.push({
            sequence: data["sequence"],
            url: data["url"],
            item: data
        })
    }

    if (data.items) {
        data.children = [];
        for (let i = 0; i < data.items.length; i++) {
            var child = processor(data.items[i], data["url"], hierarchy || data.config && data.config.hierarchy, context, depth + 1, i, data["sequence"] + '.');
            var url = child["url"];
            data.children.push(url);
        }
    }


    return data

}


function enhanceData(context) {

    const data = context.sequencelist;

    data.forEach(function (item, index) {

        // if item has type, clickable
        if (item.item["routable"]) {
            const previousItem = findPreviousItem(data, index, item["sequence"]);
            const nextItem = findNextItem(data, index, item["sequence"]);
            //
            item.item["previous"] = previousItem ? previousItem["url"] : null;
            item.item["next"] = nextItem ? nextItem["url"] : null;
        } else {
            const alternative = findAlternative(data, index, item["sequence"]);
            item.item["alternative"] = alternative ? alternative["url"] : null;
        }
    })

}

function findPreviousItem(data, index, sequence) {
    var item = null;
    for (var i = index - 1; i > 0; i--) {
        // first make sure it has type
        // second make sure under same root parent
        if (data[i]) {
            if (data[i]["routable"]) {
                item = data[i];
                break;
            }
        }
    }
    return item;
}

function findNextItem(data, index, sequence) {
    var item = null;
    for (var i = index + 1; i < data.length; i++) {
        if (data[i]) {
            if (data[i]["item"]["routable"]) {
                item = data[i];
                break;
            }
        }
    }
    return item;
}

function findAlternative(data, index, sequence) {
    for (var i = index + 1; i < data.length; i++) {
        if (data[i]) {
            if (sequence.charAt(0) < data[i].sequence.charAt(0)) {
                return null;
            }
            if (data[i]["item"]["routable"]) {
                return data[i];
            }
        }
    }
    return null;
}
