/**
 * Created by aaikepae on 11/3/16.
 */

import React from "react"

import {generateLink} from '../../../common/utility/utils'
import {parseURL} from "../../../common/utility/link"
import NavItemHeaderNavigation from "./NavItemHeaderNavigation"


export default class NavItem extends React.Component {

    constructor() {
        super();
        this.toggleMenu = this.toggleMenu.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.selected.item == nextProps.selected.item && this.props.selected.section != nextProps.selected.section) {
            this.indicate(nextProps.selected.section, this.props.selected.section);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        //if just change section, don't update
        if (this.props.selected.item == nextProps.selected.item && this.props.selected.section != nextProps.selected.section) {
            return false;
        } else {
            return true;
        }
    }

    componentDidUpdate() {
        this.indicate(this.props.selected.section)
    }

    indicate(section, previousSection) {
        //remove old active state
        if (previousSection) {
            let el;
            try {
                el = this.refs["item"].querySelector(`[data-link="${generateLink(previousSection)}"]`);
            } catch (e) {
            }
            if (el) {
                el.classList.remove("active");
            }
        }


        const indicator = this.refs["item"].querySelector(".arrow-indicator");
        if (!indicator) {
            return false
        }

        //add new active state
        //move indicator
        if (!section) {
            indicator.style.display = "none";
            return
        } else {
            indicator.style.display = "inline-block";
        }

        var active_el = null;
        try {
            active_el = this.refs["item"].querySelector(`[data-link="${generateLink(section)}"]`);
        } catch (e) {
            return false;
        }
        if (active_el) {
            //
            const top = active_el.offsetTop;
            indicator.style.top = (top + 5) + "px";
            //
            active_el.classList.add("active");
        }

    }


    toggleMenu(event) {
        var sub_ul = event.currentTarget.parentElement.parentElement.queryChild("ul");
        if (sub_ul) {
            event.currentTarget.querySelector('.arrow').classList.toggle('active');
            if (event.currentTarget.querySelector('.arrow').classList.contains('active')) {
                sub_ul.style.display = 'block';
            } else {
                sub_ul.style.display = 'none';
            }
        }

        event.stopPropagation();
        event.preventDefault();
        return false

    }


    _generateLink() {
        const {data, selected, config, toggle} = this.props;
        const items = data["items"];
        const active = selected.item && data["active"] || data["expand"] ? 'active' : null;
        const expandTopLevel = data.depth == 0 && data["config"] && data["config"]["expand"] !== undefined;

        const arrow = items && (data.depth > 0 || expandTopLevel) ?
            <span className="arrowContainer" onClick={this.toggleMenu}><span
                className={"arrow " + active}/></span> : null;

        let linkIcon = data["config"] && data["config"]["icon"] ?
            <span className={"nav-item-icon " + data["config"]["icon"]} data-sequence={data["sequence"]}/> : null;


        // if type is url, just generate a commen link
        if (data["type"] == "url") {

            // support internal url 
            if(data.content.startsWith("#!")){
                return <a href={data.content} >{data.title}</a>
            }

            const target = data["config"] && data["config"]["target"] ? data["config"]["target"] : "_blank";
            const class_name = target == "_blank" ? "hyperlink" : null;
            return <a href={data.content} target={target} className={class_name}>{data.title}</a>

        }

        if (data["type"] == "file") {
            const file_url = parseURL(data.content, config.location);
            return <a href={file_url} target="_blank" className="hyperlink">{data.title}</a>

        }

        if (data["type"] || data["alternative"]) { //generate navigate item with sub_navigation

            const uri = data["alternative"] || data["url"];

            const url = this.props.router.generateLink(uri);

            return <a href={url} data-depth={data.depth} data-tag="navigation-item"
                      data-url={uri} onClick={(event) => {
                          if (this.props.router.mode === 'history') {
                            event.preventDefault();
                            this.props.router.queryString = uri;
                          }
                          toggle();
                      }}>{linkIcon}{data.title}{arrow}</a>;
        }

        return <a>{linkIcon}{data.title}{arrow}</a>;
    }

    render() {
        const {data, selected, config, toggle} = this.props;

        if (data) {

            /**
             * generate children
             */

            const active = selected.item && data["active"] ? 'active' : null;
            const deprecated = data.config && data.config.deprecated && ' deprecated' || '';

            let children = null;
            const items = data["items"];
            if (items) {
                const _children = items.map((item, index) => {
                    return <NavItem key={item.url || index} data={item} selected={selected} config={config}
                                    router={this.props.router} toggle={toggle} />
                });

                children = <ul style={{ display: active && 'block' }}>{_children}</ul>;
            }

            const linkEL = this._generateLink();

            const subNav = <NavItemHeaderNavigation data={data} selected={selected} config={config}
                                                    router={this.props.router} toggle={this.props.toggle} />;

            return <li className={active + deprecated} ref="item" data-url-type={data["type"]} data-expand={data.expand}>
                {linkEL}
                {subNav}
                {children}
            </li>
        } else {
            return <li/>;
        }
    }
}
