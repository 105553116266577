/**
 * Created by aaikepae on 11/3/16.
 */
var obj = {
  items: null,
  itemsObj: null,
  meta: null,
  config: {
    location: null,
    defaultCodeLanguage: null,
    codeLayout: "one-column", //"two-columns",
    showCodeSwitch: true,
    layout: "navigate", // 'full': hide nav; 'navigate': show nav
    showPageNavigationBtn: true,
    showSubNavigation: true,
    showSingleSubNavigation: false,
    expandNavigation: false, // 23.5.8 Looks like not in use
    mergeSameLanguageCode: false,
    codeSectionTitleMapping: null,
    useNewNav: false,
    _navTopOffset: null,
    _platform: null,
  },
  selected: {
    itemURL: null,
    item: null,
    section: null,
    type: null,
    data: null,
    sequence: null,
    codeSections: null,
    currentCodeSection: -1
  },
  preference: {
    codeLayout: null
  },
  states: {},
  search: {
    state: null, // searching & fetched
    keyword: null,
    page: 1,
    result: []
  }
};


export default obj;
