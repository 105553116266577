import React from "react";

import { parseURL } from '../../../../../common/utility/link';
import { escapeRegex } from '../../../../../common/utility/regex';
import Icon from "./Icon";

function Item({ setSequence, item, selected, router, keyword, onClick }) {
  let { title } = item;
  if (keyword) {
    const reg = new RegExp(escapeRegex(keyword), 'gi');
    title = title.replace(reg, (x) => `<strong>${x}</strong>`);
  }

  if (item.type === 'url') {
    let target = '_blank';
    if (item.content.startsWith("#!")) {
      target = '_self';
    }
    if (item.config && item.config.target) {
      target = item.config.target;
    }

    return (
      <a className="doc-header__item" href={item.content} target={target}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
        <Icon className="doc-header__item-arrow" type={target === '_blank' ? 'arrow-up-right' : 'arrow'} />
      </a>
    )
  }

  if (item.type === 'file') {
    return (
      <a className="doc-header__item" href={parseURL(item.content, config.location)} target="_blank" >
        <span dangerouslySetInnerHTML={{ __html: title }} />
        <Icon className="doc-header__item-arrow" type="arrow-up-right" />
      </a>
    )
  }

  if (!item.type || item.alternative) {
    return (
      <span onClick={() => setSequence(`${item.sequence}.0`)} className="doc-header__item" >
        <span dangerouslySetInnerHTML={{ __html: title }} />
        <Icon className="doc-header__item-arrow" type="chevron" />
      </span>
    )
  }

  return (
    <a
      className={`doc-header__item ${item.sequence === selected.sequence ? 'active' : ''}`}
      href={router.generateLink(item.url)}
      onClick={(event) => {
        if (router.mode === 'history') {
          event.preventDefault();
          router.queryString = item.url;
        }
        if (onClick) onClick();
      }}
    >
      {item.config && item.config.icon && <i className={`doc-header__item-icon doc-header__item-icon--${item.config.icon}`} />}
      <span dangerouslySetInnerHTML={{ __html: title }} />
    </a>
  );
}

export default Item;
