import React from "react";

const DOC_TYPES = [
    "warn",
    "success",
    "primary"
];

const DEFAULT_TYPE = DOC_TYPES[0];

export default class extends React.Component {
    render() {
        const config = this.props.config;
        const label = this.getDocLabel(config);
        if (!label) return null;

        return (
            <div className="col-label">
                <div className={"doc-label label-" + label.type}>{label.text}</div>
            </div>
        );
    }

    getDocLabel(config) {
        if(!config || !config["doc-label"]) {
            return null;
        }

        const label = config["doc-label"];

        const typeOfLabel = typeof(label);

        if (typeOfLabel == "string") {
            return {
                type: DEFAULT_TYPE,
                text: label
            }
        }

        if (typeOfLabel == "object") {
            const labelText = (label.text || "");
            if (!labelText) return null;

            return {
                type: this.parseDocLabelType(label.type),
                text: labelText
            }
        }

        return null;
    }

    parseDocLabelType(type) {
        const labelType = (type || "").toLowerCase();
        return DOC_TYPES.includes(labelType) ? labelType : DEFAULT_TYPE;
    }
}
