/**
 * Created by aaikepae on 11/4/16.
 */
import initialState from '../store/initialState';

const emptyDataForOrphen = {
  orphenLinkUrl: undefined, 
  dataTempBackupForOrhenLink: undefined
}

export default function reducer(state = initialState, action) {
  if (action.type == "SELECT_ITEM") {
    if (state["itemURL"] == action.payload.url) {
      return {...state, section: action.payload.section}
    } else {
      return {
        ...state,
        "itemURL": action.payload.url,
        "section": action.payload.section,
        "item": action.payload.item,
        "sequence": action.payload.sequence,
        "routable": action.payload.routable,
        "type": null,
        "data": action.payload.data||null,
        "codeSections": null,
        "currentCodeSection": 0
      }
    }
  } else if (action.type == "UPDATE_CODE_SECTION") {
    return {...state, codeSections: action.payload}
  } else if (action.type == "UPDATE_CURRENT_CODE_SECTION") {
    return {...state, currentCodeSection: action.payload}
  } else if (action.type == "SELECT_CONTENT_TYPE") {
    return {...state, type: action.payload}
  } else if (action.type == "FETCH_STATIC_ORPHEN_LINK") {
    return (state.dataTempBackupForOrhenLink) ? state : {
      ...state, 
      dataTempBackupForOrhenLink: state.data,
      data: null,
    }
  } else if (action.type == "FETCH_STATIC_ORPHEN_LINK_FULFILLED") {
    return {
      ...state, 
      data: action.payload.data,
      orphenLinkUrl: action.payload.url,
    }
  } else if (action.type == "ORPHEN_LINK_GOBACK") {
    return {
      ...state, 
      ...emptyDataForOrphen,
      data: state.dataTempBackupForOrhenLink,
    }
  } else if (action.type == "FETCH_STATIC_CONTENT_FULFILLED") {
    return {...state, ...emptyDataForOrphen, data: action.payload.data }
  } else if (action.type == "FETCH_STATIC_CONTENT_REJECTED") {
    return state
  } else if (action.type == "CLEAR_SELECTED") {
    return {...initialState.selected}
  }else if (action.type == "CLEAR") {
    return {...initialState.selected}
  }
  return state
}

