import React from "react";
import { escapeRegex } from '../../../../common/utility/regex';

const MAX_LENGTH = 400;
const ELLIPSIS = '...';

export default class extends React.Component {
  render() {
    const { meta, router } = this.props;
    const { result, keyword } = this.props.search;
    const { url_path } = meta;

    // const converterCanonicalMap = this._getResultConverterCanonicalMap(router);

    const content = result.items
      .map(ri => {
        const item = ri.source
        let { url = '' } = item;
        const itemType = this._getItemType(item);
        const description = this._getDescription(ri);
        const apiContent = this._renderForAPI(item);

        const basePath = router.mode === 'history' ? router.baseURL : window.location.pathname;
        url = url.replace(url_path, basePath);

        const isInProject = item.meta && item.meta.url_path === url_path; 
        if (isInProject && router.mode === 'history') {
          url = url.replace('#!', '');
        }

        return (
          <div className="pubhub-search-list-item" key={item.id}>
            <a
              href={url}
              data-track-name="Doc:Search"
              data-track-value={url}
              onClick={(e) => {
                if (isInProject && router.mode === 'history') {
                  e.preventDefault();
                  router.navigateTo(url.replace(basePath, ''));
                }
              }}
            >
              <span className="pubhub-search-list-item-name"
                dangerouslySetInnerHTML={{ "__html": this._getName(ri, meta.name) }}></span>
              <span className="pubhub-search-list-item-type">{itemType}</span>
            </a>
            {apiContent}
            <div className="pubhub-search-list-item-description"
              dangerouslySetInnerHTML={{ "__html": description }}>
            </div>
          </div>
        );
      });

    return (
      <div className="pubhub-search-list">
        {content}
      </div>
    );
  }

  _getName(i, metaName) {
    const name = i.highlight.name && i.highlight.name.length > 0 ? i.highlight.name[0] : i.source.name;
    if (!name) return '';

    const splitter = " - ";
    const list = name.split(" - ");
    
    const firstItem = this._getPlainText(list[0]);
  
    if (firstItem != metaName) {
      return name;
    }

    return list.slice(1).join(splitter);
  }

  _getPlainText(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent;
  }

  _getDescription(i) {
    const { highlight, source } = i;
    const isAPI = (source.type || '').toLowerCase() == "api";

    if (isAPI) {
      return (this._getDescriptionFromHighLightField(highlight.description)
        || this._getDescriptionFromSourceField(source.description)
        || this._getDescriptionFromHighLightField(highlight.contentFullText)
        || this._getDescriptionFromSourceField(source.contentFullText));
    }

    return (this._getDescriptionFromHighLightField(highlight.contentFullText)
        || this._getDescriptionFromSourceField(source.contentFullText)
        || this._getDescriptionFromHighLightField(highlight.description)
        || this._getDescriptionFromSourceField(source.description));
  }

  _getDescriptionFromHighLightField(field) {
    if (!field || !field.length) return '';

    const list = [];
    let length = 0;
    for(let i = 0; i < field.length; i++) {
      const item = field[i];
      list.push(item);
      length += item.length;
      if (length >= MAX_LENGTH) {
        break;
      }
    }

    return list.join(ELLIPSIS + " ");
  }

  _getDescriptionFromSourceField(field) {
    if (!field) return '';

    return field.length > MAX_LENGTH ? field.substr(0, MAX_LENGTH) + ELLIPSIS : field;
  }

  _renderForAPI(item) {
    if ((item.type || '').toLowerCase() != "api") return null

    if (!item.meta || !item.meta.api_content) return null;

    const apiContent = this._tryParseJson(item.meta.api_content);

    if (!apiContent) return null;

    const spec = apiContent.spec || {};

    const operationId = spec.operationId && <span className="api-operationid">{spec.operationId}</span>
    const tags = (spec.tags || []).map(i => <span key={i} className="api-tag">{i}</span>);
    const path = (spec.method || '').toUpperCase() + ' ' + (spec.path || '')

    return (
      <div className="pubhub-search-list-item-api">
        <div className="api-tags">
          {operationId}
          {tags}
        </div>
        <div className="api-uri">
          {path}
        </div>
      </div>
    );
  }

  _getItemType(item) {
    const type = item.type || '';
    return type.toLowerCase() == "PubHubDocContent".toLowerCase() ? 'Doc' : type;
  }

  _tryParseJson(text) {
    if (!text) return null;

    try {
      return JSON.parse(text);
    } catch (err) {
      console.log(err)
      return null;
    }
  }

  // _getResultConverterCanonicalMap(router) {
  //   const pathList = window["PUBHUB_CANONICAL_PATH_LIST"] || {};

  //   let pathname = router.mode === 'history' ? router.baseURL : window.location.pathname

  //   if (!pathList[pathname]) return {};

  //   return Object.keys(pathList).reduce((pre, cur) => {
  //     const url = pathList[cur].url;

  //     return {
  //       ...pre,
  //       [url]: cur
  //     }
  //   }, {});
  // }

  // _convertItemUrl(url, converterCanonicalMap) {
  //   const pathName = url.split("#")[0];
  //   const convertedPathName = converterCanonicalMap[pathName];
  //   return convertedPathName ? url.replace(pathName, convertedPathName) : url;
  // }
}