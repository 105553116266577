/**
 * Created by aaikepae on 11/7/16.
 */
import React from "react"
import {connect} from "react-redux"
import CodeTabs from "../../../../widgets/basic/codeTab/CodeTabs";
import Setting from "../setting/Setting"

export class CodePanel extends React.Component {

  constructor(props) {
    super(props);

    this.jumpToSection = this.jumpToSection.bind(this);
  }


  jumpToSection(id) {
    window.location.href = "#!" + this.props.selected.itemURL + "/" + id;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!nextProps.selected.item) {
      return true
    }
    if (nextProps.selected.section !== this.props.selected.section) {
      return false;
    }

    return true;
  }

  render() {

    const {selected, config} = this.props;


    if (config["_platform"] !== "lg") {
      return <div className="pubhub-doc-code-panel pubhub-doc-code-panel-hide " ref="root">
        <div className="pubhub-doc-code-panel-content"/>
      </div>
    }

    if (!selected.codeSections || !selected.codeSections.length || selected.currentCodeSection == -1 || !selected.item) {
      return <div className="pubhub-doc-code-panel pubhub-doc-code-panel-hide" ref="root">
        <div className="pubhub-doc-code-panel-content"/>
      </div>
    }

    if (config.codeLayout !== "two-columns") {
      return <div className="pubhub-doc-code-panel pubhub-doc-code-panel-deactive" ref="root">
        <Setting {...this.props}/>
        <div className="pubhub-doc-code-panel-content"/>
      </div>
    }

    let title, tab;
    if (selected.codeSections && selected.codeSections.length) {
      const section = selected.codeSections[selected.currentCodeSection]; //
      title = (
        <div className="pubhub-doc-code-panel-title" onClick={() => this.jumpToSection(section.id)}>
          {section["title"]}
        </div>
      );

      tab = <div className="pubhub-doc-code-panel-content">
        <CodeTabs groups={section.groups}/>
      </div>
    }

    return (
      <div className="pubhub-doc-code-panel" ref="root">
        <Setting {...this.props}/>
        {title}
        {tab}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    selected: state.selected,
    config: state.config
  };
}


export default connect(mapStateToProps, null, null)(CodePanel);
