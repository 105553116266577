/**
 * Created by aaikepae on 11/3/16.
 */
import initialState from '../store/initialState';

export default function reducer(state = initialState.preference, action) {
  if (action.type == "UPDATE_PREFERENCE") {
    return {...state, [action.payload.key]: action.payload.value}
  } else if (action.type == "CLEAR") {
    return {...initialState.config}
  }

  return state
}
